<template>
  <v-container>
    <v-data-table
      :loading="loading_data_table"
      :headers="headers"
      :items="desserts"
      sort-by="corporate_name"
      class="elevation-1"
      :pageCount="numberOfPagesPaginate"
      :page="currentPagePaginate"
      :server-items-length="totalStagesPaginate"
      :options.sync="options"
      :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true,
      }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Medições</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="loadingRefresh"
                :loading="loadingRefresh"
                @click="hitoricMetering()"
                fab
                small
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-list-box </v-icon>
              </v-btn>
            </template>
            <span>Histórico</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-text-field
            outlined
            rounded
            dense
            v-model="search"
            append-icon="mdi-magnify"
            label="Localizar"
            @click:append="searchItems()"
            @change="searchItems()"
            single-line
            hide-details
            class="mr-8"
          ></v-text-field>
          <v-btn @click="newMetering()" rounded color="primary">
            <v-icon small>mdi-plus</v-icon>
            medição
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip v-if="ifContractActual(item)" small color="success">Aprovado</v-chip>
        <v-chip v-if="!ifContractActual(item)" small color="warning">Não Aprovado</v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="item.approved == 'yes'"
              color="secondary"
              @click="showSummary(item.contractor)"
              v-bind="attrs"
              v-on="on"
              icon
              class="mr-2"
            >
              <v-icon color="secondary"> mdi-eye </v-icon>
            </v-btn>
          </template>
          <span>Visualizar</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="item.approved == 'not'"
              color="secondary"
              @click="showSummary(item.contractor)"
              v-bind="attrs"
              v-on="on"
              icon
              class="mr-2"
            >
              <v-icon color="primary"> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="secondary"
              v-bind="attrs"
              v-on="on"
              icon
              class="mr-2"
              @click="showHistoric(item.contractor)"
            >
              <v-icon> mdi-list-box </v-icon>
            </v-btn>
          </template>
          <span>Histórico</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="item.approved == 'yes'"
              color="secondary"
              @click="confirmDelete(item)"
              v-bind="attrs"
              v-on="on"
              icon
              class="mr-2"
              ><v-icon color="error"> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>Excluir</span>
        </v-tooltip>
        <!-- <v-icon class="mr-2" v-if="item.metering_contract.approved=='yes'"  color="primary" @click="showSummary(item)"> mdi-eye </v-icon> -->
      </template>

      <template v-slot:item.cnpj="{ item }">
        {{ formatCNPJ(item.contractor.cnpj) }}
      </template>
      <template v-slot:item.cpf="{ item }">
        {{ formatCPF(item.contractor.cpf) }}
      </template>

      <template v-slot:no-data> Nenhum registro encontrado </template>
    </v-data-table>

    <DialogContractorSummary
      v-model="dialogContractorSummary"
      @refreshTable="initialize()"
      :contractor="contractorSummary"
    >
    </DialogContractorSummary>
    <MsgDelete
      :loading="loadingDelete"
      @confirm="sendDelete()"
      v-model="dialogDelete"
      message="Deseja excluir essa medição ?"
    ></MsgDelete>
    <!-- <DialogNewMetering v-model="dialogNewMetering"></DialogNewMetering> -->
  </v-container>
</template>

<script>
import { mapActions, mapMutations } from "vuex/dist/vuex.common.js";
import DialogContractorSummary from "./_components/DialogContractorSummary.vue";
import MsgDelete from "@/pages/Components/MsgDelete.vue";
// import DialogNewMetering from "./_components/DialogNewMetering.vue";

export default {
  components: {
    DialogContractorSummary,
    MsgDelete,
    // DialogNewMetering
  },
  data: () => ({
    loadingDelete: false,
    dialogDelete: false,
    dialogNewMetering: false,
    dialogContractorSummary: false,
    contractorSummary: {},

    loadingRefresh: false,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    options: {},
    search: "",

    btnLoadingSave: false,
    loading_data_table: false,
    dialog: false,

    headers: [
      {
        text: "EMPREITEIRO",
        align: "start",
        sortable: false,
        value: "contractor.fantasy_name",
      },
      // {
      //   text: "RAZÃO SOCIAL",
      //   value: "company_name"
      // },
      {
        text: "CNPJ",
        value: "cnpj",
      },
      {
        text: "CPF",
        value: "cpf",
      },
      {
        text: "STATUS",
        value: "status",
      },
      {
        text: "",
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      code: "",
      name: "",
    },
    defaultItem: {
      id: "",
      code: "",
      name: "",
    },
  }),

  computed: {},

  watch: {
    dialog(val) {
      val || this.close();
    },
    // dialogDelete(val) {
    //   val || this.closeDelete();
    // },
    options: {
      handler() {
        this.initialize();
      },
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions("Metering", ["index", "destroy"]),
    ...mapMutations("Contractor", ["setContractor"]),
    initialize() {
      this.loading_data_table = true;
      const { page, itemsPerPage } = this.options;
      let data = {
        itemsPerPage: itemsPerPage || 10,
        page,
        search: this.search,
      };
      this.index(data).then((response) => {
        this.desserts = response.data.data;
        this.totalStagesPaginate = response.data.total;
        this.numberOfPagesPaginate = response.data.last_page;
        this.currentPagePaginate = response.data.current_page;
        this.loading_data_table = false;
      });
    },
    searchItems() {
      this.options = {};
      this.initialize();
    },

    /**
     * Msg de delete
     */
    confirmDelete(contract) {
      this.meteringContractDelete = contract;

      this.dialogDelete = true;
    },

    /**
     * deletar um contrato de medicao
     */
    sendDelete() {
      this.loadingDelete = true;
      this.destroy(this.meteringContractDelete)
        .then(() => {
          this.dialogDelete = false;
          this.$toast.success("Medição excluída");
          this.desserts.splice(this.desserts.indexOf(this.meteringContractDelete), 1);
        })
        .catch((error) => {
          if (error.response.status == 423) {
            this.$toast.error(error.response.data);
          }
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    },

    showSummary(contractor) {
      this.dialogContractorSummary = true;
      this.contractorSummary = contractor;
    },
    newMetering() {
      this.contractorSummary = {};
      this.dialogContractorSummary = true;
    },
    showHistoric(contractor) {
      this.setContractor(contractor);
      this.$router.push({
        name: "contractor_historic",
        params: { contractor_id: contractor.id },
      });
    },
    hitoricMetering() {
      this.$router.push("/system/physical_progress/historic_metering");
    },
    // newMetering(){
    //     this.dialogNewMetering = true
    // },
    ifContractActual(item) {
      if (item) {
        if (item.approved == "yes") {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    formatCNPJ(cnpj) {
      if (cnpj.length == 14) {
        return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }
      return "";
    },
    formatCPF(cpf) {
      if (cpf.length == 11) {
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
      }
      return "";
    },
  },
};
</script>
