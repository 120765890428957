<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-card>
      <BarTitle @close="closeDialog()" title="Adicionar Serviço"></BarTitle>
      <v-card-text>
        <v-container>
          <v-row>
            <!-- <v-col>
              <v-select
                @change="getStages()"
                v-model="cost"
                :items="costs"
                return-object
                item-value="id"
                item-text="name"
                outlined
                label="Custo"
              ></v-select>
            </v-col> -->
            <v-col>
              <v-select
                :loading="loadingStage"
                :disabled="loadingStage"
                v-model="stage"
                :items="stages"
                item-value="id"
                return-object
                outlined
                label="Etapa"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
                <v-autocomplete
                :items="services"
                :loading="loadingService"
                :disabled="loadingService"
                v-model="service"
                item-value="id"
                return-object
                outlined
                label="Serviço"
                 ></v-autocomplete>

            </v-col>
            <v-col cols="4">
              <vuetify-money
                label="Quantidade"
                outlined
                v-model="quantity"
                :clearable="true"
                valueWhenIsEmpty="0"
                :options="{
                  locale: 'pt-BR',
                  length: 11,
                  precision: 2,
                }"
              />
              <!-- <v-text-field v-model="quantity" outlined label="Quantidade"></v-text-field> -->
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mr-2" x-large color="secondary" text @click="closeDialog()"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          x-large
          :disabled="loadingBtnAddService"
          :loading="loadingBtnAddService"
          @click="saveService()"
          >Adicionar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BarTitle from "@/pages/Components/BarTitle.vue";
import { mapActions } from "vuex";
import { mapMutations } from "vuex";
export default {
  components: {
    BarTitle,
  },
  props: {
    value: { type: Boolean },
    contractor: { type: Object },
  },
  data() {
    return {
      loadingBtnAddService: false,
      loadingStage: false,
      loadingService: false,
      dialog: false,
      services: [],
      service: {},
      cost: null,
      costs: [],
      stages: [],
      stage: null,
      compositions: [],
      composition: null,
      quantity: null,
    };
  },
  watch: {
    value(val) {
      this.dialog = val;
      //limpa os campos
      (this.cost = {}),
        (this.stage = {}),
        (this.composition = {}),
        (this.quantity = null),
        //carrega custos
        // this.getCost().then((response) => {
        //   this.costs = response.data;
        // });
        (this.loadingStage = true);
      this.getStagesPerCostCurrent()
        .then((response) => {
          this.stages = response.data;
        })
        .finally(() => {
          this.loadingStage = false;
        });

      //carregar os serviços
      this.loadingService = true;
      this.getServiceContractor(this.contractor)
        .then((response) => {
          this.services = response.data;
        })
        .finally(() => {
          this.loadingService = false;
        });
    },
  },

  methods: {
    ...mapActions("Metering", [
      "getCost",
      "getServiceContractor",
      "getStagesPerCostCurrent",
      "getQuantityAvailableContractor",
    ]),
    ...mapMutations("MeteringDetached", ["addService"]),
    closeDialog() {
      this.$emit("input", false);
      this.dialog = false;
    },

    // getStages() {
    //   this.getStagesPerCost(this.cost.id).then((response) => {
    //     this.stages = response.data;
    //   });
    // },

    getServices() {
      this.getServiceAvailable().then((response) => {
        this.services = response.data;
      });
    },

    saveService() {
      //validar campos
      if (this.stage.id == undefined || this.service.id == undefined) {
        this.$toast.error("Todos os campos são obrigatórios");
        return false;
      }

      if (this.quantity <= 0) {
        this.$toast.error("Quantidade deve ser maior que zero.");
        return false;
      }

      let data = {
        contractor: this.contractor,
        service: this.service,
        quantity: this.quantity,
      };
      this.loadingBtnAddService = true;
      this.getQuantityAvailableContractor(data)
        .then(() => {
          let service = {
            stage: this.stage,
            service: this.service,
            quantity: this.quantity,
          };
          this.$emit("confirm", service);
          //   this.addService(service);

          this.closeDialog();
        })
        .catch((error) => {
          if (error.response.status == 423) {
            this.$toast.error(error.response.data);
          }
        })
        .finally(() => {
          this.loadingBtnAddService = false;
        });
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
